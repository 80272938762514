import axios, { AxiosError } from "axios";

import { UseToastOptions } from "@chakra-ui/react";

export { useGetCitizenship } from "./useGetCitizenship";
export { useMutationRequest } from "./useMutationRequest";
export { useGetCryptoEnabled } from "./useGetCryptoEnabled";
export { useGetAccountDetails } from "./useGetAccountDetails";
export { useGetCountryInfos } from "./useGetCountryInfos";
export { useKeyPress } from "./useKeyPress";
export { useLock } from "./useLock";

export { useGetPositions } from "./useGetPositions";
export { useGetPaperAccounts } from "./useGetPaperAccounts";
export { useGetStatus } from "./useGetStatus";
export { useGetMargin } from "./useGetMargin";
export { useGetAsset } from "./useGetAsset";
export { useGetOrderbooks } from "./useGetOrderbooks";
export { useGetPosition } from "./useGetPosition";
export { useGetClock } from "./useGetClock";
export { useGetOrders } from "./useGetOrders";
export { useGetCryptoSnapshots } from "./useGetCryptoSnapshots";
export { useGetAchRelationshipsPending } from "./useGetAchRelationshipsPending";
export { useGetAccessKeys } from "./useGetAccessKeys";

export { usePlaid } from "./usePlaid";
export { useGetBillingOverview } from "./useGetBillingOverview";
export { useOnfido } from "./useOnfido";
export { useRequest } from "./useRequest";
export { useGetCountries } from "./useGetCountries";
export { useGetAccount } from "./useGetAccount";
export { useGetTrustedContact } from "./useGetTrustedAccount";
export { useGetActivities } from "./useGetActivities";

export { useGetAchRelationships } from "./useGetAchRelationships";
export { useGetAchTransfers } from "./useGetAchTransfers";
export { useGetBankInstitution } from "./useGetBankInstitution";
export { useGetRapydTransfers } from "./useGetRapydTransfers";

export { useGetWatchlists } from "./useGetWatchlists";
export { useGetWatchlist } from "./useGetWatchlist";
export { useGetQuotes } from "./useGetQuotes";

export { useGetCryptoFeeStructure } from "./useGetCryptoFeeStructure";
export { useGetCryptoMonthlyVolume } from "./useGetCryptoMonthlyVolume";

export { useGetIpAddress } from "./useGetIpAddress";

export { useGetOptionsApprovalStatus } from "./useGetOptionsApprovalStatus";
export { useGetOwners } from "./useGetOwners";
export { useGetOwnerDocuments } from "./useGetOwnerDocuments";

export { useGetEliteNetDeposits } from "./useGetEliteNetDeposits";
export { useGetEliteAlgoRouting } from "./useGetEliteAlgoRouting";
export { useGetElitePerks } from "./useGetElitePerks";

export const defaultToastSettings: UseToastOptions = {
  title: "An error occurred",
  status: "error",
  duration: 3000,
  isClosable: true,
};

export const getErrorMessage = (err: Error | AxiosError): string => {
  if (axios.isAxiosError(err)) {
    const resp = err.response?.data;
    return resp?.debug || resp?.message;
  }
  return err.message;
};
