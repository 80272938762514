// @todo mocked until flag-service is deprecated
export const setFlag = (flag: string, value: boolean) =>
  localStorage.setItem(flag, value ? "true" : "false");

// @todo mocked until flag-service is deprecated
export const useFlag = (flag: string) => {
  const value = localStorage.getItem(flag);

  // again, mocked until flag-service is deprecated
  if (flag === "uix-v2") {
    return true;
  }

  return value === "true";
};
