import moment from "moment";

import React, { useMemo, createContext, useState, useEffect } from "react";

import {
  Table,
  TableColumn,
  Button,
  Icon,
  Text,
  classes,
  Page,
} from "@alpacahq/ui";

import {
  useGetAccount,
  useGetAchTransfers,
  useGetRapydTransfers,
  useGetAchRelationships,
  useGetActivities,
} from "src/v2/api/hooks";

import { Account } from "../../../api/rest/account";
import { useDisclosure } from "@chakra-ui/react";
import { Wizard, TransferType } from "./transfer/wizard";
import { Transfer, AchRelationship } from "../../../api/rest/banking";
import { getPrettyCash, getPrettyDate } from "../../../utils/formatting";

export const TransferDirection = {
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING",
} as const;

export type TransferDirection = typeof TransferDirection[keyof typeof TransferDirection];

export enum PendingRelationshipType {
  ADDED,
  DELETED,
}

// shared context for the transfer wizard
export type BankingContextFields = {
  account?: Account;
  disclosure: ReturnType<typeof useDisclosure>;
  transferDirection: TransferDirection | undefined;
  wireDirection: TransferDirection | undefined;
  setTransferType: (type: TransferType | null) => void;
  setTransferDirection: (direction: TransferDirection | undefined) => void;
  setWireDirection: (direction: TransferDirection | undefined) => void;
  transferType: TransferType | null;
  wizardFormId: string | null;
  setWizardFormId: (id: string | null) => void;
  nextStep: boolean | null;
  setNextStep: (nextStep: boolean | null) => void;
  onBeforeClose: () => void;
  refetch: () => void;
  relationships: AchRelationship[];
  setRelationships: (relationships: AchRelationship[]) => void;
  relationshipPending: boolean;
  setRelationshipPending: (
    pending: boolean,
    type?: PendingRelationshipType
  ) => void;
};

// default to empty object (easier for children to null check fields)
export const BankingContext = createContext<BankingContextFields>(
  {} as BankingContextFields
);

export const Banking = () => {
  const { account } = useGetAccount();

  const [
    transferDirection,
    setTransferDirection,
  ] = useState<TransferDirection>();

  const [nextStep, setNextStep] = useState<boolean | null>(false);
  const [wizardFormId, setWizardFormId] = useState<string | null>(null);
  const [transferType, setTransferType] = useState<TransferType | null>(null);
  const [wireDirection, setWireDirection] = useState<TransferDirection>();
  const [relationships, setRelationships] = useState<AchRelationship[]>([]);
  const [relationshipPending, setRelationshipPending] = useState(false);

  // pre-populate local storage if trying to set relationship pending
  const onBeforeSetRelationshipPending = (
    pending: boolean,
    type?: PendingRelationshipType
  ) => {
    // save polling expiration to local storage
    localStorage.setItem(
      "relationship:pending",
      JSON.stringify({
        expires: Date.now() + 180000,
        type,
      })
    );

    // update state
    setRelationshipPending(pending);
  };

  // don't want to re-run this hook
  useGetAchRelationships(account?.id, ["relationships"], {
    enabled: !!account?.id,
    refetchInterval: 5000,
    staleTime: 5000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    onSuccess: (data) => {
      setRelationships(data || []);
    },
  });

  // these are wire transfers
  const { activities, refetch: refetchActivities } = useGetActivities(
    {
      accountID: account?.id || "",
      // only want to fetch CSD and CSW activities
      // CSD = incoming (deposit)
      // CSW = outgoing (withdrawal)
      activityTypes: ["CSD", "CSW"],
      // a year ago in ISO format
      after: moment().subtract(1, "year").startOf("day").toISOString(),
      until: moment().endOf("day").toISOString(),
    },
    {
      enabled: !!account,
    }
  );

  const { achTransfers, refetch: refetchACH } = useGetAchTransfers(
    account?.id || "",
    "ach-transfers",
    {
      enabled: !!account?.id,
    }
  );

  const { rapydTransfers, refetch: refetchRapyd } = useGetRapydTransfers(
    account?.id || "",
    "rapyd-transfers",
    {
      enabled: !!account?.id,
    }
  );

  // refetch both ACH and Rapyd transfers
  const refetchTransfers = () => {
    refetchACH();
    refetchRapyd();
    refetchActivities();
  };

  // mapping activities to transfer-like objects
  const activityTransfers =
    activities?.map((activity) => ({
      id: activity.id,
      status: activity.status,
      amount: parseFloat(activity.net_amount),
      created_at: activity.date,
      type: activity.activity_type,
      direction:
        activity.activity_type === "CSD"
          ? TransferDirection.INCOMING
          : TransferDirection.OUTGOING,
    })) ?? [];

  // transfer history rows
  const rows = useMemo<Transfer[]>(
    () =>
      (
        rapydTransfers?.concat(achTransfers || []).concat(activityTransfers) ??
        []
      )
        // sort in descending order
        .sort((a, b) => moment(b.created_at).diff(moment(a.created_at))),
    [rapydTransfers, achTransfers, activities]
  );

  // transfer history columns
  const columns = useMemo<TableColumn<Transfer>[]>(
    () => [
      { name: "ID", key: "id" },
      {
        name: "Status",
        key: "status",
        cellRenderer: ({ status }) => status?.toLowerCase() ?? "-",
      },
      { name: "Type", key: "type" },
      {
        name: "Amount",
        key: "amount",
        alignRight: true,
        cellRenderer: ({ amount, status, direction }) => {
          const color =
            status !== "COMPLETE"
              ? "text-gray-500"
              : // if incoming transfer, color is green
              // if outgoing transfer, color is red
              direction === TransferDirection.INCOMING
              ? "text-emerald-500"
              : "text-rose-500";

          // normalize the amount
          const value = Number(amount).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });

          return (
            <div className="flex w-full justify-between">
              <Icon
                className={classes("mr-3", color)}
                name={
                  direction === TransferDirection.INCOMING
                    ? "ArrowDownLeft"
                    : "ArrowUpRight"
                }
              />
              <Text className={color}>{getPrettyCash(value)}</Text>
            </div>
          );
        },
      },
      {
        name: "Date",
        key: "created_at",
        cellRenderer: ({ created_at }) =>
          getPrettyDate(moment(created_at).toDate().getTime()),
      },
    ],
    [rows]
  );

  // used to manage the transfer wizard state
  const disclosure = useDisclosure();

  // parse direction from url
  const direction = useMemo(() => {
    // if param exists we know the direction in advance
    const params = new URLSearchParams(window.location.search);
    const transfer = params.get("transfer");

    // normalize
    if (["deposit", "withdraw"].includes(transfer?.toLowerCase() || "")) {
      return transfer === "deposit"
        ? TransferDirection.INCOMING
        : TransferDirection.OUTGOING;
    }

    // default to state
    return transferDirection;
  }, [window.location.search]);

  useEffect(() => {
    if (direction) {
      setWireDirection(direction);
      setTransferDirection(direction);
      disclosure.onOpen();
    }
  }, []);

  // if transfers are loading, we aren't ready to show the table
  const ready = !!rapydTransfers && !!achTransfers;

  const onBeforeWizardOpen = (direction: TransferDirection) => {
    // set the direction for both wire and transfer
    setWireDirection(direction);
    setTransferDirection(direction);

    // pop the modal
    disclosure.onOpen();

    // human-readable direction
    const safeDirection =
      direction === TransferDirection.INCOMING ? "deposit" : "withdraw";

    // update the url to reflect the direction
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?transfer=${safeDirection}`
    );
  };

  const onBeforeWizardClose = () => {
    // reset states
    setNextStep(false);
    setTransferType(null);

    // pop the modal
    disclosure.onClose();

    // remove the url param
    window.history.replaceState({}, "", window.location.pathname);
  };

  const goToBankingV1 = () => {
    // add v1=true to the url
    window.location.href = `${window.location.pathname}?v1=true`;
  }

  return (
    <BankingContext.Provider
      value={{
        refetch: refetchTransfers,
        account,
        disclosure,
        transferDirection,
        setTransferDirection,
        wireDirection,
        setWireDirection,
        transferType,
        setTransferType,
        wizardFormId,
        setWizardFormId,
        nextStep,
        setNextStep,
        onBeforeClose: onBeforeWizardClose,
        relationshipPending,
        setRelationshipPending: onBeforeSetRelationshipPending,
        relationships,
        setRelationships,
      }}
    >
      <Page
        title="Banking"
        subtitle="Deposit and withdraw funds from your account."
        className="p-8 pt-0 xs:pl-0 md:p-8 md:pt-0 md:pl-0"
      >
        {disclosure.isOpen ? (
          <Wizard />
        ) : (
          <Table
            searchEnabled
            maxHeight={450}
            emptyMessage="No transfers found"
            uniqueKey="page:banking"
            columns={columns}
            rows={rows}
            pageSize={10}
            paginationEnabled={true}
              tools={[
              <Button variant="alternate" onClick={goToBankingV1}>
                Banking V1
              </Button>,
              <Button
                variant="alternate"
                onClick={() => onBeforeWizardOpen(TransferDirection.OUTGOING)}
              >
                Withdraw Funds
              </Button>,
              <Button
                onClick={() => onBeforeWizardOpen(TransferDirection.INCOMING)}
              >
                Deposit Funds
              </Button>,
            ]}
          />
        )}
      </Page>
    </BankingContext.Provider>
  );
};

export default Banking;
