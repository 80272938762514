import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  ReactNode,
} from "react";

import {
  UIContext,
  SidebarState,
  clsx,
  Icon,
  IconName,
  Tooltip,
  Button,
  getHashCode,
} from "@alpacahq/ui";

export interface SidebarItemProps {
  label: ReactNode;
  leftIcon?: IconName;
  subItems?: ConditionalSidebarItemProps[];
  href?: string;
  active?: boolean;
  newTab?: boolean;
  divider?: boolean;
  unresponsive?: boolean;
  onClick?: () => void;
}

export type ConditionalSidebarItemProps =
  | false // item can be false to conditionally render it
  | (SidebarItemProps &
      (
        | { subItems: never[]; href: string } // if the item has subItems, it can't have an href
        | { subItems?: ConditionalSidebarItemProps[]; href?: string }
      )); // same as default props for SidebarItemProps

export const SidebarItem: React.FC<ConditionalSidebarItemProps> = (props) => {
  // if the item is false, don't render anything (used for conditional rendering)
  if (typeof props === "boolean") {
    return null;
  }

  const {
    label,
    leftIcon,
    subItems,
    href,
    divider = false,
    newTab = false,
    active = false,
    onClick,
    unresponsive = false,
  } = props;

  const { history, sidebarState } = useContext(UIContext);

  const checkIsActive = (item: ConditionalSidebarItemProps) => {
    if (typeof item === "boolean") {
      return false;
    }

    const { href, active } = item;
    return active || (href && window.location.href.includes(href));
  };

  const isActive = useMemo(() => checkIsActive(props), [active, href]);

  const [isOpen, setIsOpen] = useState(false);
  const [manualCollapseTriggered, setManualCollapseTriggered] = useState(false);
  const [filteredSubItems, setFilteredSubItems] = useState<SidebarItemProps[]>(
    []
  );

  useEffect(() => {
    if (subItems) {
      // @ts-ignore get around typescript complaining about the conditional props
      setFilteredSubItems(subItems.filter(Boolean));
    }
  }, [subItems]);

  useEffect(() => {
    if (!manualCollapseTriggered && filteredSubItems.some(checkIsActive)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [manualCollapseTriggered, filteredSubItems]);

  // short-form for checking if the sidebar is collapsed
  const isCollapsed = unresponsive
    ? false
    : sidebarState === SidebarState.COLLAPSED;

  const handleItemClick = () => {
    // if the item has sub-items, toggle the dropdown and track the manual collapse status
    if (subItems) {
      if (isOpen && !manualCollapseTriggered) {
        setManualCollapseTriggered(true);
      } else {
        setIsOpen(!isOpen);
        setManualCollapseTriggered(false);
      }
    }

    // if the item has a href, navigate to it
    if (href) {
      if (newTab) {
        window.open(href, "_blank");
      } else {
        history.push(href);
      }
    }
  };

  return (
    <>
      <div className="relative flex flex-col">
        <Tooltip
          content={label}
          hidden={!isCollapsed}
          placement={isCollapsed ? "right" : "top"}
        >
          <Button
            className={
              "flex w-full justify-start h-10 rounded-lg" +
              (isActive ? " bg-zinc-100" : "")
            }
            variant={isActive ? "secondary" : "ghost"}
            leftIcon={leftIcon ? <Icon name={leftIcon} /> : undefined}
            onClick={() => {
              handleItemClick();
              if (onClick) {
                onClick();
              }
            }}
          >
            {!isCollapsed && (
              <span className="flex w-full flex-grow justify-start truncate">
                {label}
              </span>
            )}
            {!!subItems && (
              <Icon
                name={isOpen ? "ChevronDown" : "ChevronRight"}
                className={clsx(
                  "h-4 w-4 stroke-[3px]",
                  isCollapsed && "hidden"
                )}
              />
            )}
          </Button>
        </Tooltip>
        {isOpen && (
          <div className={clsx("mt-0.5 space-y-0.5", !isCollapsed && "pl-2")}>
            {filteredSubItems.map((subItem) => (
              <SidebarItem key={getHashCode(subItem)} {...subItem} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarItem;
