import moment from "moment";

import React, { useEffect, useState } from "react";

import { Text } from "@alpacahq/ui";
import { Auth } from "aws-amplify";
import { EditProfileFormClone } from "./pages/User/ProfilePage/ProfileContainer/AccountSecurity";
import { useGetAccount } from "./v2/api/hooks";
import { Card } from "./v2/components";

export const MFA_ENFORCED_DATE = "2024-06-13";
export const MFA_DUE_DATE = "2024-09-30";
export const WARNING_KEY = "mfa_warning";

const canRenderMFAGuard = (account, cognitoUser) => {
  if (
    cognitoUser?.preferredMFA === "NOMFA" &&
    moment(account?.created_at).isSameOrAfter(MFA_ENFORCED_DATE, "day")
  ) {
    return true;
  }

  return false;
};

export const canRenderWarning = (cognitoUser) => {
  return cognitoUser?.preferredMFA === "NOMFA";
};

export const MFAGuard = ({ children }) => {
  const { account } = useGetAccount();

  const [cognitoUser, setCognitoUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setCognitoUser(user))
      .catch((err) => console.error("MFAGuard err", err));
  }, []);

  // Render a loader or a placeholder while loading
  if (!account?.created_at) {
    return <div className="text-zinc-500">Checking MFA status...</div>;
  }

  // Render MFA guard if conditions are met
  if (canRenderMFAGuard(account, cognitoUser)) {
    return (
      <Card title="MFA is required" className="mr-8">
        <Text className="text-md opacity-75">
          Please enable MFA on your account before continuing. You can do so by
          choosing an authentication type below.
        </Text>
        <EditProfileFormClone />
      </Card>
    );
  }

  // Render children if no MFA guard is needed
  return children;
};
