import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import {
  PlaidAccount,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import { useQueryClient } from "react-query";
import { Account } from "src/v2/api/rest/account";
import {
  createBankRelationship,
  verifyBankRelationship,
  MutateBankRelationshipParams,
  VerifyBankRelationshipArgs,
  BankRelationships,
  getBankRelationships,
} from "../rest/relationships";
import * as Sentry from "@sentry/browser";
import { CaptureContext } from "@sentry/types";

import { getPlaidAuthStatus, getPlaidLinkToken } from "../rest/plaid";
import { ACHRelationship } from "../rest/relationships";
import { getOnfidoSdkToken } from "../rest/onfido";
import useRequest, { QueryOptions } from "./useRequest";
import useMutationRequest from "./useMutationRequest";
import AmplitudeProvider from "src/AmplitudeProvider";

type PlaidLinkToken = { expiration: string; link_token: string };
const usePlaidLinkToken = (
  accountID: string,
  options: QueryOptions<PlaidLinkToken>
) =>
  useRequest<PlaidLinkToken>(
    ["plaid-token", accountID],
    () => getPlaidLinkToken(accountID),
    options
  );

export const usePlaidLinkStatus = (
  accountID: string,
  options: QueryOptions<ACHRelationship>
) =>
  useRequest(
    ["plaid-status", accountID],
    () => getPlaidAuthStatus(accountID),
    options
  );

// hook to integrate plaid
// it is a light wrapper on top of react-plaid-link
type UsePlaidCallbacks = {
  onSuccess?: PlaidLinkOnSuccess;
  onExit?: PlaidLinkOnExit;
};

export const usePlaid = (
  account: Account | undefined,
  cb: UsePlaidCallbacks = {},
  createRelationshipCb?: () => void
) => {
  const accountID = account?.id ?? "";
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: relationshipResp } = useAccountRelationships(accountID, {
    enabled: Boolean(account),
  });

  const { mutate: createRelationship } = useMutationRequest(
    (body: MutateBankRelationshipParams) =>
      createBankRelationship(accountID, body),
    { onSuccess: createRelationshipCb }
  );

  const { mutate: verifyRelationship } = useMutationRequest(
    (body: VerifyBankRelationshipArgs) => verifyBankRelationship(body)
  );

  const { data: linkResp } = usePlaidLinkToken(accountID, {
    enabled: Boolean(account),
  });

  const relationships = relationshipResp ?? [];
  const hasRelationship = relationships.length > 0;
  const relationship = hasRelationship ? relationships[0] : null;
  const token = linkResp?.link_token ?? null;

  const onSuccess: PlaidLinkOnSuccess = async (public_token, md) => {
    // overwrite metadata type
    const metadata = md as PlaidLinkOnSuccessMetadata & {
      account: PlaidAccount;
      account_id: string;
    };

    // accountID for our id
    // account_id for plaid account id
    const account = metadata?.accounts?.[0] ?? metadata?.account;

    const account_id = account?.id;
    const name = account?.name;
    const mask = account?.mask;

    if (!account || !accountID || !name || !mask) {
      const errMsg = "plaid metadata is not available";
      const info = {
        account: metadata.account,
        account_id,
        name,
        mask,
        accounts: metadata.accounts,
      };

      toast({
        status: "error",
        description: errMsg,
      });

      console.error(errMsg, info);
      Sentry.captureException(new Error(errMsg), info as CaptureContext);
      return;
    }

    let verification_status = account?.verification_status;
    // Status is omitted when null. Seems that this is the result of either
    // a successful instant or manual auth. If it's a manual auth we can
    // check the auth status manually

    // I dont think this check is even needed.
    // verification_status is always null.
    // getPlaidAuthStatus simply sets it to "" instead of null which can be done client side... ??
    if (!verification_status && hasRelationship) {
      // Status is omitted when null. Seems that this is the result of either
      // a successful instant or manual auth. If it's a manual auth we can
      // check the auth status manually.

      // TODO FIX type here
      const authInfo = await queryClient.fetchQuery(
        ["plaid-auth", account_id],
        () => getPlaidAuthStatus(account_id)
      );
      verification_status = authInfo?.plaid_verification_status ?? "";

      if (!verification_status) {
        toast({
          status: "error",
          description: "plaid link result contained empty verification_status",
        });
        return;
      }
    }

    const body: MutateBankRelationshipParams = {
      plaid: {
        public_token,
        account_id,
        name: name,
        mask: mask,
        verification_status: verification_status,
      },
    };
    // create relationship or verify relationship based on verification status
    switch (verification_status) {
      case "manually_verified":
        verifyRelationship({
          accountID: accountID,
          relationshipID: relationship?.id ?? "",
          body,
        });
        break;
      case "pending_automatic_verification":
      case "pending_manual_verification":
      default:
        createRelationship(body);
        break;
    }

    AmplitudeProvider.dispatch("plaid_finished", {
      verification_status: verification_status,
      account_id: account_id,
    });

    // call success callback if provided
    cb.onSuccess && cb.onSuccess(public_token, metadata);
  };

  const onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
    let eventData;
    switch (eventName) {
      case PlaidLinkStableEvent.OPEN: {
        eventData = {
          event: PlaidLinkStableEvent.OPEN,
          view_name: metadata.view_name,
        };
        break;
      }
      case PlaidLinkStableEvent.HANDOFF: {
        eventData = {
          event: PlaidLinkStableEvent.HANDOFF,
          view_name: metadata.institution_name,
        };
        break;
      }
      case PlaidLinkStableEvent.EXIT: {
        eventData = {
          event: PlaidLinkStableEvent.EXIT,
          exit_status: metadata.exit_status,
          error_code: metadata.error_code,
          error_type: metadata.error_type,
          institution_name: metadata.institution_name,
        };
        break;
      }
      case PlaidLinkStableEvent.ERROR: {
        eventData = {
          event: PlaidLinkStableEvent.ERROR,
          error_code: metadata.error_code,
          error_message: metadata.error_message,
          error_type: metadata.error_type,
          institution_name: metadata.institution_name,
        };
        break;
      }
      default:
        break;
    }

    if (eventData) {
      AmplitudeProvider.dispatch("plaid_event", JSON.stringify(eventData));
    }
  };

  const onExit = useCallback<PlaidLinkOnExit>((err, metadata) => {
    // custom behavior for exit called here
    cb.onExit && cb.onExit(null, metadata);

    // no errors nothing to do
    if (!err) return;

    const info = {
      err,
      request_id: metadata.request_id,
      session_id: metadata.link_session_id,
    };
    const errMsg = `plaid link error encountered: ${err.error_code}`;

    toast({
      title: "Something went wrong",
      status: "error",
      description: errMsg,
    });

    console.error(errMsg, info);
    Sentry.captureException(new Error(errMsg), info as CaptureContext);
  }, []);

  const plaidLink = usePlaidLink({
    token,
    onSuccess,
    onExit,
    onEvent,
    userLegalName: account?.name,
    userEmailAddress: account?.email,
  });

  return plaidLink;
};

const useAccountRelationships = (
  accountID: string,
  options: QueryOptions<BankRelationships>
) =>
  useRequest(
    ["relationships", accountID],
    () => getBankRelationships(accountID),
    options
  );

export default usePlaid;
